var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// /* eslint-disable react/display-name */
// /* eslint-disable @typescript-eslint/no-unused-vars */
// /* eslint-disable react/prop-types */
// /** @format */
// import React from 'react';
// import { Parent, Text, Icon } from '..';
// import { InputProps } from '../../../interface/interfaceProps';
// const Input: React.FC<InputProps> = React.forwardRef<InputProps>((props: any, ref) => {
//     return (
//         <Parent>
//             {props.labelRequired ? (
//                 <Parent className="flex items-center">
//                     <Text
//                         htmlFor={props.id}
//                         className={props.labelClass}
//                         as="label"
//                         label={props.labelText}
//                     />
//                     {props.astrix === 'not null' ? (
//                         <Text as="label" className="block text-sm ml-1 font-medium text-red-700">
//                             *
//                         </Text>
//                     ) : (
//                         ''
//                     )}
//                 </Parent>
//             ) : (
//                 ''
//             )}
//             <Parent className=" relative">
//                 {props.iconRequired ? (
//                     <Parent className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
//                         <Icon
//                             tag={props.iconTag}
//                             className="h-5 w-5 text-gray-300"
//                             aria-hidden="true"
//                         />
//                     </Parent>
//                 ) : (
//                     ''
//                 )}
//                 <input
//                     type={props.type}
//                     name={props.name}
//                     value={props.value}
//                     ref={(el: any) => (ref = el)}
//                     id={props.id}
//                     className={props.className}
//                     placeholder={props.placeHolder}
//                     {...props}
//                     onBlur={props.onBlur}
//                     onChange={props.onChange}
//                     onClick={props.onClick}
//                     required={props.required}
//                     accept={props.accept}
//                     onKeyUp={props.onKeyUp}
//                     checked={props.checked}
//                     disabled={props.disabled}
//                     autoFocus={false}
//                     min={props.type == 'number' ? 0 : ''}
//                 />
//             </Parent>
//         </Parent>
//     );
// });
// export default Input;
import React from 'react';
import { Parent, Text, Icon } from '..';
const Input = React.forwardRef((props, ref) => {
    // Destructure custom props to avoid passing them to the input element
    const { labelRequired, labelClass, labelText, astrix, iconRequired, iconTag, placeHolder } = props, inputProps = __rest(props, ["labelRequired", "labelClass", "labelText", "astrix", "iconRequired", "iconTag", "placeHolder"]);
    return (_jsxs(Parent, { children: [labelRequired && (_jsxs(Parent, { className: "flex items-center", children: [_jsx(Text, { htmlFor: inputProps.id, className: labelClass, as: "label", label: labelText }), astrix === 'not null' && (_jsx(Text, { as: "label", className: "block text-sm ml-1 font-medium text-red-700", children: "*" }))] })), _jsxs(Parent, { className: "relative", children: [iconRequired && (_jsx(Parent, { className: "absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none", children: _jsx(Icon, { tag: iconTag, className: "h-5 w-5 text-gray-300", "aria-hidden": "true" }) })), _jsx("input", Object.assign({ ref: ref, placeholder: placeHolder }, inputProps, { min: inputProps.type === 'number' ? 0 : undefined }))] })] }));
});
// Add display name to the component
Input.displayName = 'Input';
export default Input;
