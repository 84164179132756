/* eslint-disable no-dupe-keys */
/** @format */

export const TRANSLATIONS_DE = {
    dashboard: 'Armaturenbrett',
    home: 'Heimat',
    settings: 'die Einstellungen',
    addons: 'Addons',
    products: 'Produkte',
    configuration: 'Aufbau',
    customer: 'Kundin',
    customer_view: 'Kundenansicht',
    customer_table: 'Kundentabelle',
    employee: 'Angestellter',
    employee_role: 'Mitarbeiter Rolle',
    employee_type: 'Mitarbeitertyp',
    employee_attendance: 'Mitarbeiter Anwesenheit',
    items: 'Artikel',
    add_item: 'Artikel hinzufügen',
    edit_item: 'Objekt bearbeiten',
    fixed_amount: 'fester Betrag',
    View_Item: 'Artikel ansehen',
    Profile: 'Profil',
    payment_type: 'Zahlungsart',
    role: 'Rolle',
    tax: 'Steuer',
    unit: 'Einheit',
    uom: 'Maßeinheit',
    users: 'Benutzer',
    variation: 'Variation',
    report: 'Bericht',
    pos_session: 'Besitz',
    outlet_settings: 'Ausgangseinstellungen',
    outlet_details: 'Outlet Details',
    outlet_timings: 'Auslasszeiten',
    general: 'Allgemein',
    coupon: 'Coupon',
    add_coupon: 'Gutschein hinzufügen',
    edit_coupon: 'Gutschein bearbeiten',
    tax_details: 'Steuerdetails',
    tip_handle: 'Spitze Griff',
    manual: 'Handbuch',
    auto: 'Auto',
    loyalty: 'Loyalität',
    loyalty_settings: 'Loyalitätseinstellungen',
    coupons_redemption: 'Coupon-Einlösung',
    loyalty_points: 'Treuepunkte',
    invoice_summary: 'Rechnungszusammenfassung',
    inventory_summary: 'Bestandsübersicht',
    sales_summary: 'Zusammenfassung der Verkäufe',
    high_sales: 'Höchster Umsatz',
    customer_summary: 'Kundenzusammenfassung',
    tax_on_off: 'Steuer EIN/AUS',
    item_on_off: 'Element EIN/AUS',
    available_qty: 'Verfügbare Anzahl',
    apply_discount: 'Rabatt anwenden',
    search: 'Suche',
    filter: 'Filter',
    open_options: 'Optionen öffnen',
    coupon_loyalty: ' Gutschein / Treue',
    selected: 'Ausgewählt ',
    all: 'Alle',
    image: 'Bild',
    name: 'Name',
    sku: 'SKU',
    price: 'Preis ',
    on_off_Start_Cash: 'EIN/AUS Start Cash ',
    browse: 'Durchsuche',
    actions: 'Aktionen',
    product_details: '  Produktdetails',
    product_code_sku: 'Produktcode / SKU',
    product_name: 'Produktname',
    brand_name: 'Markenname',
    category: 'Kategorie',
    sale_unit: 'Verkaufseinheit',
    sale_quantity: 'Verkaufsmenge',
    barcode: 'Strichcode',
    upi_payment: 'UPI Zahlung',
    upi: 'UPI',
    mark_as_favorite: ' Als Favorit markieren',
    age_restricted: ' Altersbeschränkung',
    description: ' Beschreibung',
    pricing: ' Preisgestaltung',
    discount_type: ' Rabatttyp',
    percent_fixed_amount: 'Prozentsatz / Festbetrag',
    fixed_amount: 'Fester Betrag',
    charge_tax_for_this_item: ' Steuern für diesen Artikel berechnen',
    images: 'Bilder',
    stock: 'Aktie',
    minimum_quantity: 'Mindestens hinzufügen',
    minimum_quantity_is_to_show_alert_when_quantity_goes_low:
        ' Die Mindestmenge soll eine Warnung anzeigen, wenn die Menge zur Neige geht',
    variant_name: 'Variantenname ',
    quantity: 'Menge',
    add_on: 'Addons',
    enable_in_pos: 'Im POS aktivieren',
    selected_a_tab: ' Wählen Sie eine Registerkarte aus',
    clear: ' Klar',
    update: 'Aktualisieren',
    save: 'Speichern',
    add: 'Hinzufügen',
    product_code_is_required: 'Produktcode ist erforderlich',
    product_name_is_required: 'Produktname ist erforderlich',
    sale_quantity_is_required: 'Verkaufsmenge ist erforderlich',
    select_category_is_required: 'Kategorie auswählen ist erforderlich',
    select_sub_category_is_required: 'Unterkategorie auswählen ist erforderlich',
    price_is_required: 'Preis ist erforderlich',
    minimum_quantity_is_required: 'Mindestmenge erforderlich',
    available_quantity_is_required: 'Verfügbare Menge ist erforderlich',
    brand_name_is_required: 'Markenname ist erforderlich',
    enter_category_name: 'Geben Sie den Kategorienamen ein',
    page_size: 'Seitengröße',
    next: ' Nächste',
    previous: 'Vorherige',
    showing: 'Anzeigen',
    to: 'zu',
    of: ' von',
    results: ' Ergebnisse',
    option_1: 'Option 1',
    option_2: 'Option 2',
    apply_tax: 'Steuern anwenden',
    apply: ' Sich bewerben',
    cancel: 'Absagen',
    note: ' Notiz',
    set_to_on_if_you_want_to_charge_tax_for_this_item_in_pos_set_to_off_if_you_do_not_want_to_charge_tax_for_this_item_in_pos:
        'Stellen Sie ON ein, wenn Sie Steuern für diesen Artikel in POS berechnen möchten. Stellen Sie dies auf AUS, wenn Sie für diesen Artikel im POS keine Steuern berechnen möchten.',
    set_to_off_if_you_do_not_want_to_show_this_item_in_pos_set_to_on_if_you_want_to_show_this_item_in_pos:
        'Stellen Sie OFF ein, wenn Sie diesen Artikel nicht im POS anzeigen möchten. Auf EIN stellen, wenn Sie diesen Artikel im POS anzeigen möchten.',
    this_quantity_will_be_added_to_the_already_available_quantity_eg_if_there_5_products_in_inventory_and_available_quantity_applied_is_100_after_this_operation_available_quantity_becomes_105:
        ' Diese Menge wird zu der bereits verfügbaren Menge addiert. Beispiel: Wenn 5 Produkte im Bestand sind und die verfügbare Menge 100 beträgt, beträgt die verfügbare Menge nach diesem Vorgang 105.',
    discount_is_applied_by_percentage_only: 'Der Rabatt wird nur prozentual angewendet.',
    yes_delete_it: 'Ja, löschen',
    yes: 'Ja',
    no: 'Nein',
    add_customer: 'Kunde hinzufügen',
    first_name: ' Vorname',
    last_name: '  Nachname',
    mobile: ' Handy, Mobiltelefon',
    date_of_birth: ' Geburtsdatum',
    email: 'Email',
    area: ' Bereich',
    browse_photo: ' Foto durchsuchen',
    click_to_add_profile_picture: ' Klicken Sie hier, um ein Profilbild hinzuzufügen',
    zipcode: ' Postleitzahl',
    tax_number: ' Steuernummer',
    address_1: '  Adresse',
    do_not_disturb: ' Bitte nicht stören',
    first_name_is_required: 'Vorname ist erforderlich',
    first_name_not_more_than_25_characters: 'Vorname nicht mehr als 25 Zeichen',
    last_name_is_required: 'Nachname ist erforderlich',
    last_name_not_more_than_25_characters: 'Nachname nicht mehr als 25 Zeichen',
    email_not_valid: 'E-Mail ungültig',
    email_is_required: 'E-Mail ist erforderlich',
    mobile_is_required: 'Handy ist erforderlich',
    mobile_number_is_required: 'Handynummer ist erforderlich',
    mobile_not_more_than_12_numbers: 'Handy nicht mehr als 12 Nummern',
    mobile_number_not_more_than_15_characters: 'Handynummer nicht mehr als 15 Zeichen',
    address_1_not_more_than_150_characters: 'Adresse 1 nicht mehr als 150 Zeichen',
    zip_not_more_than_10_characters: 'Zip nicht mehr als 10 Zeichen',
    close_panel: ' Bedienfeld schließen',
    birthday: ' Geburtstag',
    address_2: 'Adresse 2',
    add_category: 'Kategorie hinzufügen',
    category_name: 'Kategoriename',
    on_off: 'AN AUS',
    tag_name: ' Verlinke den Namen',
    category_image: 'Kategoriebild',
    upload_from_device: ' Vom Gerät hochladen',
    upload_from_repository: 'Vom Gerät hochladen',
    category_name_is_required: 'Kategoriename ist erforderlich',
    category_name_not_more_than_50_characters: 'Kategoriename nicht mehr als 50 Zeichen',
    tag_name_is_required: 'Tag-Name ist erforderlich',
    edit_category: 'Kategorie bearbeiten',
    sales_trend: 'Verkaufstrend',
    inventory: ' Inventar',
    highest_selling_items: 'Meistverkaufte Artikel',
    business_name: 'Firmenname',
    shop_name: 'Laden Name',
    business_owner: ' Geschäftsinhaber',
    owner_mobile: ' Besitzer Handy',
    owner_email: 'Besitzer-E-Mail',
    outlets: ' Verkaufsstellen',
    want_to_add_outlet: 'Steckdose hinzufügen möchten',
    click_here: 'Klicken Sie hier',
    common_setting: '  Allgemeine Einstellungen',
    language: ' Sprache',
    currency: 'Währung',
    notification: ' Benachrichtigung',
    daily_sales_report: 'Täglicher Umsatzbericht',
    send_daily_sales_report_from_all_outlets_in_one_email:
        'Senden Sie den täglichen Verkaufsbericht von allen Verkaufsstellen in einer E-Mail',
    send_monthly_annual_sale_report_from_all_outlets_in_one_email:
        'Senden Sie monatliche Jahresumsatzberichte von allen Verkaufsstellen in einer E-Mail',
    send_inventory_alert_report_from_all_outlets_in_one_email:
        'Senden Sie Bestandsalarmberichte von allen Verkaufsstellen in einer E-Mail',
    send_monthly_customer_data_report_from_all_outlets_in_one_email:
        'Senden Sie monatliche Kundendatenberichte von allen Verkaufsstellen in einer E-Mail',
    monthly_annual_sale_report: 'Monatlicher Jahresverkaufsbericht',
    inventory_alert: 'Bestandsalarm',
    monthly_customer_data: 'Monatliche Kundendaten',
    use_setting: 'Einstellung verwenden',
    edit_cover: ' Cover bearbeiten',
    contact_details: 'Kontaktdetails',
    tax_master: 'Steuermeister',
    employment_types: 'Beschäftigungsarten',
    add_tax: 'Steuern hinzufügen',
    add_variation: 'Variante hinzufügen',
    add_addon: 'Addon hinzufügen',
    add_uom: 'Maßeinheit hinzufügen',
    add_unit: 'Einheit hinzufügen',
    add_employment_type: 'Beschäftigungsart hinzufügen',
    add_employment_role: 'Beschäftigungsrolle hinzufügen',
    add_payment_type: 'Zahlungsart hinzufügen',
    open_time: 'Offene Zeit',
    close_time: ' Schließzeit',
    day_of_week: 'Wochentag',
    leave: 'Verlassen',
    add_user: 'Nutzer hinzufügen',
    add_role: 'Rolle hinzufügen',
    add_employee: 'Mitarbeiter hinzufügen',
    id: 'ICH WÜRDE',
    phone_no: 'Telefon-Nr',
    type: 'Typ',
    employee_name: 'Mitarbeitername',
    employee_id: 'Mitarbeiter-ID',
    date_of_joining: ' Eintrittsdatum',
    date_of_leaving: ' Datum der Abreise',
    payment: ' Zahlung',
    employee_name_is_required: 'Der Name des Mitarbeiters ist erforderlich',
    employee_name_not_more_than_25_characters: 'Mitarbeitername nicht mehr als 25 Zeichen',
    employee_id_is_required: 'Mitarbeiterausweis ist erforderlich',
    employment_type_is_required: 'Beschäftigungstyp ist erforderlich',
    role_is_required: 'Rolle ist erforderlich',
    payment_is_required: 'Zahlung ist erforderlich',
    phone_no_is_required: 'Telefonnummer ist erforderlich',
    address: ' Adresse',
    session_id: 'Session ID',
    opening_date: 'Eröffnungsdatum',
    closing_date: 'Einsendeschluss',
    status: 'Status',
    orders: 'Aufträge',
    amount: 'Menge',
    session: '  Sitzung',
    order_no: 'Best.-Nr',
    order_type: `Auftragsart`,
    order_value: `Bestellwert `,
    coupon_name: 'Gutscheinname',
    coupon_code: 'Gutscheincode',
    valid_date: 'Gültiges Datum',
    redemption: 'Rücknahmen',
    valid_till: 'Gültig bis',
    percent: 'Prozent',
    coupon_users: 'Coupon-Benutzer',
    back: 'Der Rücken',
    edit_coupon_details: 'Gutscheindetails bearbeiten',
    add_coupon_details: 'Gutscheindetails hinzufügen',
    apply_discount_after_tax: 'Rabatt nach Steuern anwenden',
    valid_from: 'Gültig ab',
    coupon_details: 'Gutscheindetails',
    target_audience: 'Zielgruppe',
    favorites: 'Favoritinnen',
    finish: 'Fertig',
    coupon_name_is_required: 'Gutscheinname ist erforderlich',
    coupon_code_is_required: 'Gutscheincode ist erforderlich',
    discount_type_is_required: 'Rabatttyp ist erforderlich',
    percent_is_required: 'Prozent / fester Betrag ist erforderlich',
    reedem_points: 'Punkte einlösen',
    value: 'Wert',
    loyalty_points: 'Treuepunkte',
    total_loyalty: 'Totale Loyalität',
    order_value_d: 'Bestellwert',
    in_progress: 'Im Gange',
    pay_type: 'Zahlungsart',
    settled: 'Erledigt',
    cancelled: 'Abgesagt',
    returned: 'Ist zurückgekommen',
    biller: '  Biller',
    order_status: ' Bestellstatus',
    card_details: '  Kartendetails',
    customer_details: 'Kundendetails',
    paid: 'Bezahlt',
    date: 'Datum',
    time: 'Zeit',
    discount: 'Rabatt',
    tax: 'Steuer',
    grand_total: ' Gesamtsumme',
    total: ' Gesamt',
    item_name: 'Artikelname',
    unit_price_$: 'Stückpreis',
    total_price_$: 'Gesamtpreis',
    business_image: 'Geschäftsbild',
    upload: ' Hochladen',
    invoice_no: 'Rechnungs-Nr',
    payment: 'Zahlung',
    welcome_to: 'Willkommen zu',
    providing_affordable_one_step_payment_solutions_across_the_globe_to_support_growing_businesses:
        'Bereitstellung erschwinglicher One-Step-Zahlungslösungen auf der ganzen Welt, um wachsende Unternehmen zu unterstützen',
    loading: 'Wird geladen',
    employees: 'Angestellte',
    filter_category: 'Kategorie filtern',
    order_details: 'Bestelldetails',
    edit_customer: 'Kunde bearbeiten',
    edit_business: 'Unternehmen bearbeiten',
    created_date: 'Erstellungsdatum',
    edit_user: 'Benutzer bearbeiten',
    edit_role: 'Rolle bearbeiten',
    view_coupon: 'Gutschein ansehen',
    open_time_is_required: 'Offene Zeit ist erforderlich',
    close_time_is_required: 'Schließzeit ist erforderlich',
    event_is_required: 'Ereignis ist erforderlich',
    redemption_point_is_required: 'Einlösepunkt ist erforderlich',
    condition_is_required: 'Bedingung ist erforderlich',
    value_is_required: 'Wert erforderlich',
    points_is_required: 'Punkte sind erforderlich',
    loyalty_empty: 'Loyalität leer',
    do_you_want_to_enable_loyalty_for_your_outlet:
        ' Möchten Sie Loyalität für Ihr Outlet aktivieren?',
    enable_loyalty: 'Aktivieren Sie Loyalität',
    point_setting: ' Punkteinstellung',
    points: 'Punkte',
    value: 'Wert',
    points_equals: ' Punkte gleich',
    reward_rules: 'Belohnungsregeln',
    event: 'Vorfall',
    condition: ' Bedingung',
    order_value: ' Bestellwert',
    redemption_rules: 'Einlösungsregeln',
    customer_can_redeem_when: 'Kunde kann wann einlösen',
    profile_details: 'Profildetails',
    edit_profile: 'Profil bearbeiten',
    contact_number: 'Kontakt Nummer',
    change_password: 'Passwort ändern',
    password: 'Passwort',
    reset_password: 'Passwort zurücksetzen',
    current_password_is_required: 'Aktuelles Passwort ist erforderlich',
    current_password_not_match: 'Aktuelles Passwort stimmt nicht überein',
    new_password_is_required: 'Neues Passwort erforderlich',
    new_password_is_too_short_should_be_5_chars_minimum:
        'Neues Passwort ist zu kurz - sollte mindestens 5 Zeichen lang sein',
    confirm_password_is_required: 'Passwort bestätigen ist erforderlich',
    passwords_must_match: 'Die Passwörter müssen übereinstimmen',
    current_password: 'Jetziges Passwort',
    new_password: 'Neues Passwort',
    confirm_password: 'Passwort bestätigen',
    update_profile: 'Profil aktualisieren',
    update_password: 'Kennwort aktualisieren',
    change: 'Veränderung',
    coupen_code: 'Gutscheincode',
    discount_value: 'Rabattwert',
    installation: 'Installation',
    pos_installation: 'EezzyPOS Installation',
    pos_for_windows: 'POS für Windows',
    pos_for_mac: 'POS für Mac',
    download: 'Download',
    installation_for_pos: 'Installation für POS',
    windows: 'Windows',
    mac: 'Mac',
    you_are_seeing: 'Du siehst',
    last_one_week: 'Letzte eine Woche',
    data: 'Daten',
    tracking_issues: 'Tracking-Probleme',
    new_ticket: 'Neues Ticket',
    tickets_list: 'Ticketliste',
    outlet_name: 'Outlet-Name',
    title: 'Titel',
    screen_name: 'Künstlername',
    seviority: 'Schwere',
    priority: 'Priorität',
    description: 'Beschreibung',
    file_attachments: 'Dateianlagen',
    discard: 'Verwerfen',
    defect_id: 'Fehler-ID',
    ticket_no: 'Fahrkarte Nr',
    view_ticket: 'Ticket ansehen',
    status: 'Status',
    action: 'Aktion',
    defect_no: 'Defekt Nr',
    attachments: 'Anhänge',
    download: 'Download',
    create: 'Schaffen',
    image_upload_from_device_or_from_repository: 'Bild-Upload vom Gerät oder aus dem Repository',
    total_bills: 'Rechnungen insgesamt',
    returned_bills: 'Retournierte Rechnungen',
    settled_bills: 'Beglichene Rechnungen',
    cancelled_bills: 'Stornierte Rechnungen',
    total_amount: 'Gesamtsumme',
    sales_amount: 'Verkaufsmenge',
    tax: 'Steuer',
    discount: 'Rabatt',
    card_payments: 'Kartenzahlungen',
    cash_payment: 'Barzahlungen',
    total_sales: 'Gesamtumsatz',
    total_tax: 'Gesamtsteuer',
    select_uom_is_required: 'Select uom ist erforderlich',
    isbn_upc_gtin_etc: 'ISBN, UPC, GTIN usw',
    upload_a_file: 'Eine Datei hochladen',
    png_jpg_gif_up_to_10mb: 'PNG, JPG, GIF bis zu 10 MB',
    enter_minimum_quantity: 'Geben Sie die Mindestmenge ein',
    enter_available_quantity: 'Geben Sie die verfügbare Menge ein',
    item: 'Artikel',
    apply_qty: 'Menge auftragen',
    discount_per: 'Rabatt(%)',
    are_you_sure: 'Bist du dir sicher?',
    do_you_really_want_to_delete: 'Möchten Sie wirklich löschen?',
    expenses: 'Kosten',
    products_assigned_to_the: 'Produkte, die dem zugeordnet sind',
    category_will_be_deleted_do_you_want_to_proceed:
        'Kategorie wird gelöscht. Willst du fortfahren?',
    are_you_sure_to_delete_this: 'Möchten Sie dies löschen? ',
    menu_will_be_deleted_do_you_want_to_proceed: 'Menü wird gelöscht. Willst du fortfahren?',
    customers_are_not_available_click_add_customer_to_create_new_customer:
        'Kunden sind nicht erreichbar. Klicken Sie auf Kunde hinzufügen, um einen neuen Kunden zu erstellen',
    categories_are_not_available_click_add_category_to_create_new_category:
        'Kategorien sind nicht verfügbar. Klicken Sie auf Kategorie hinzufügen, um eine neue Kategorie zu erstellen',

    coupons_are_not_available_click_add_coupon_to_create_new_coupon:
        'Gutscheine sind nicht verfügbar. Klicken Sie auf Coupon hinzufügen, um einen neuen Coupon zu erstellen',
    employee_are_not_available_click_add_employee_to_create_new_employee:
        'Mitarbeiter sind nicht erreichbar. Klicken Sie auf Mitarbeiter hinzufügen, um einen neuen Mitarbeiter zu erstellen',
    employment_role_are_not_available_click_add_employment_role_to_create_new_employment_role:
        'Beschäftigungsrolle sind nicht verfügbar. Klicken Sie auf Beschäftigungsrolle hinzufügen, um eine neue Beschäftigungsrolle zu erstellen',
    employment_type_are_not_available_click_add_employment_type_to_create_new_employment_type:
        'Beschäftigungsart sind nicht verfügbar. Klicken Sie auf Beschäftigungstyp hinzufügen, um einen neuen Beschäftigungstyp zu erstellen',
    items_are_not_available_click_add_item_to_create_new_item:
        'Artikel sind nicht verfügbar. Klicken Sie auf Artikel hinzufügen, um einen neuen Artikel zu erstellen',
    orders_are_not_available: 'Bestellungen sind nicht möglich.',
    loyalties_are_not_available: 'Loyalitäten sind nicht verfügbar.',
    payment_type_are_not_available_click_add_payment_type_to_create_new_payment_type:
        'Zahlungsarten sind nicht verfügbar. Klicken Sie auf Zahlungsart hinzufügen, um eine neue Zahlungsart zu erstellen',
    sales_summary_are_not_available: 'Verkaufsübersicht sind nicht verfügbar.',
    tax_are_not_available_click_add_tax_to_create_new_tax:
        'Steuern sind nicht verfügbar. Klicken Sie auf Steuer hinzufügen, um eine neue Steuer zu erstellen',
    unit_are_not_available_click_add_unit_to_create_new_unit:
        'Einheit sind nicht verfügbar. Klicken Sie auf Einheit hinzufügen, um eine neue Einheit zu erstellen',
    uom_are_not_available_click_add_uom_to_create_new_uom:
        'Maßeinheiten sind nicht verfügbar. Klicken Sie auf Maßeinheit hinzufügen, um eine neue Maßeinheit zu erstellen',
    user_are_not_available_click_add_user_to_create_new_user:
        'Benutzer sind nicht verfügbar. Klicken Sie auf Benutzer hinzufügen, um einen neuen Benutzer zu erstellen',
    variation_are_not_available_click_add_variation_to_create_new_variation:
        'Varianten sind nicht verfügbar. Klicken Sie auf Variante hinzufügen, um eine neue Variante zu erstellen',
    tickets_are_not_available_click_add_tickets_to_create_new_tickets:
        'Karten sind nicht erhältlich. Klicken Sie auf Tickets hinzufügen, um neue Tickets zu erstellen',
    addon_are_not_available_click_add_addon_to_create_new_addon:
        'Addons sind nicht verfügbar. Klicken Sie auf Add-on, um ein neues Add-on zu erstellen',
    product_details_are_not_available_click_select_products_or_select_categories:
        'Produktdetails sind nicht verfügbar. Klicken Sie auf Produkte auswählen oder Kategorien auswählen',
    roles_are_not_available_click_add_role_to_create_new_role:
        'Rollen sind nicht verfügbar. Klicken Sie auf Rolle hinzufügen, um eine neue Rolle zu erstellen',
    redemption_rules_are_not_available_click_add_to_create_new_redemption_rules:
        'Einlösungsregeln sind nicht verfügbar. Klicken Sie auf Hinzufügen, um neue Einlösungsregeln zu erstellen',
    tax_name: 'Steuername',
    tax_label: 'Steueretikett',
    tax_percentage: 'Steuerprozentsatz (%)',
    variation_name: 'Variationsname',
    addon_name: 'Addon-Name',
    uom_name: 'Maßeinheitsname',
    unit_name: 'Einheitenname',
    role_name: 'Rollenname',
    display_name: 'Anzeigename',
    created_date: 'Erstellungsdatum',
    payment_name: 'Zahlungsname',
    allow_pos_login: 'POS-Anmeldung zulassen',
    roles: 'Rollen',
    role_permissions: 'Rollenberechtigungen',
    edit_payment_type: 'Zahlungsart bearbeiten',
    closing_date_time: 'Abschlussdatum und -uhrzeit',
    open_date_time: 'Datum & Uhrzeit öffnen',
    view_pos_session: 'POS-Sitzung anzeigen',
    view_employee: 'Mitarbeiter ansehen',
    edit_employee: 'Mitarbeiter bearbeiten',
    timing_same_on_all_days: 'Uhrzeit an allen Tagen gleich',
    timing_same_on_all_week_days: 'Uhrzeit an allen Wochentagen gleich',
    different_on_all_days: 'An allen Tagen unterschiedlich',
    net_sales: 'Nettoumsatz',
    start_date: ' Anfangsdatum',
    end_date: ' Endtermin',
    not_exceeded_than_5_digits: 'Nicht mehr als 5 Ziffern',
    edit_variation: 'Variante bearbeiten',
    edit_addon: 'Addons bearbeiten',
    edit_uom: 'Maßeinheit bearbeiten',
    edit_unit: 'Einheit bearbeiten',
    edit_employment_type: 'Beschäftigungsart bearbeiten',
    edit_employment_role: 'Beschäftigungsrolle bearbeiten',
    tax_name_lable: 'Steuername/Etikett',
    coupon_redemption_are_not_available: 'Coupon-Einlösungen sind nicht verfügbar.',
    session: 'Sitzung',
    product_details: 'Produktdetails',
    current: 'aktuell',
    upcoming: 'bevorstehende',
    step_1: 'Schritt 1',
    step_2: 'Schritt 2',
    step_3: 'Schritt 3',
    edit_tax: 'Steuer bearbeiten',
    update_rule: 'Regel aktualisieren',
    save_rule: 'Regel speichern',
    filter_by: 'Filtern nach',
    export_csv: 'CSV-Datei exportieren',
    settled_count: 'Erledigter Graf',
    return_count: 'Anzahl zurückgeben',
    cancelled: 'Abgesagt',
    bill_amount: 'Rechnungsbetrag',
    settled_amount: 'Abgerechneter Betrag',
    return_amount: 'Rückgabebetrag',
    cancel_amount: 'Stornobetrag',
    terminal_name: 'Terminal',
    opening_date_time: 'Datum und Uhrzeit der Eröffnung',
    billed_by: 'Abgerechnet von',
    phone: 'Telefon',
    new_customer_summary: 'Neue Kundenübersicht',
    customer_id: 'Kundennummer',
    customer_on_board_summary: 'Kunden-On-Board-Zusammenfassung',
    card_amount: 'Kartenbetrag',
    cash_amount: 'Barbetrag',
    expenses_amount: 'Ausgabenbetrag',
    tax_amount: 'Steuerbetrag',
    out_of_stocks: 'Ausverkauft',
    instock_report_summary: 'Zusammenfassung des InStock-Berichts',
    quantity_available: 'Menge verfügbar',
    coupon_discount: 'Coupon-Rabatt',
    coupons: 'Gutscheine',
    title_is_required: 'Titel ist erforderlich',
    title_not_more_than_25_characters: 'Titel nicht mehr als_25_Zeichen',
    priority_is_required: 'Priorität ist erforderlich',
    description_is_required: 'Beschreibung ist erforderlich',
    description_not_more_than_150_characters: 'Beschreibung_nicht_mehr_als_150_Zeichen',
    no_reporsitory_image: 'Kein Repository-Image',

    business_information: 'Geschäftsinformationen',
    click_on: 'Klicke auf ',
    outlet_to: 'Ausgang zu',
    view_and_update_settings: 'Einstellungen anzeigen und aktualisieren',
    for_this_outlet: 'für diese Steckdose',
    product_code_is_not_less_than: 'Produktcode ist nicht kleiner als',
    character: 'Charakter',
    product_code_is_not_more_than: 'Produktcode ist nicht mehr als',
    product_name_is_not_more_than: 'Produktname ist nicht mehr als',
    product_name_is_not_less_than: 'Produktname ist nicht kleiner als',
    select_all: 'Wählen Sie Alle',
    terms_conditions: 'Terms & amp; Bedingungen',
    privacy_policy: 'Datenschutz-Bestimmungen',
    no_of_sold: 'Anzahl verkauft',
    no_category_displayed: 'Keine Kategorie angezeigt',
    start_scan: 'Scan starten',
    percentage: 'Prozentsatz',
    select: 'Auswählen ',
    on: 'An',
    off: 'Aus',
    anniversary: 'Jubiläum',
    dob: 'Geburtsdatum ',
    return: 'Zurückkehren',
    in_stock: 'Auf Lager',
    total_customers: 'Kunden insgesamt',
    new_customers: 'neue Kunden',
    view: 'Aussicht',
    click_on_the_eezzypos_for_windows_on_the_pos_till_machine_this_downloads_a_zip_file_on_the_machine:
        'Klicken Sie auf dem POS/Kassenautomaten auf eezzyPOS für Windows. Dadurch wird eine ZIP-Datei auf die Maschine heruntergeladen.',
    unzip_the_file_and_click_on_epos_exe_file_to_run_the_installation_for_pos:
        'Entpacken Sie die Datei und klicken Sie auf die Datei epos.exe, um die Installation für POS auszuführen.',
    once_the_installation_is_complete_you_can_launch_the_application_from_program_files_application_name_is_eezzypos:
        'Sobald die Installation abgeschlossen ist, können Sie die Anwendung aus den Programmdateien starten. Anwendungsname ist eezzyPOS',
    launch_the_eezzypos_application_to_access_the_pos_for_billing_purpose_login_and_use_the_billing_pos_application:
        'Starten Sie die eezzyPOS-Anwendung, um für Abrechnungszwecke auf den POS zuzugreifen. Melden Sie sich an und verwenden Sie die Abrechnungs-POS-Anwendung.',
    click_on_the_eezzypos_for_mac_on_the_pos_till_machine_this_downloads_a_zip_file_on_the_machine:
        'Klicken Sie auf dem POS/Kassenautomaten auf eezzyPOS for MAC. Dadurch wird eine ZIP-Datei auf die Maschine heruntergeladen.',
    unzip_the_file_and_click_on_epos_dmg_file_to_run_the_installation_for_pos:
        'Entpacken Sie die Datei und klicken Sie auf die Datei epos.dmg, um die Installation für POS auszuführen.',
    greater_than: 'Größer als',
    less_than: 'Weniger als',
    step: 'Schritt',
    successfully_created: 'Erfolgreich erstellt',
    items_successfully_created: 'Artikel erfolgreich erstellt',
    coupon_successfully_created: 'Gutschein erfolgreich erstellt',
    category_successfully_created: 'Kategorie erfolgreich erstellt',
    profile_successfully_updated: 'Profil erfolgreich aktualisiert',
    successfully_changed: 'Erfolgreich geändert',
    successfully_updated: 'Erfolgreich aktualisiert',
    password_successfully_changed: 'Passwort erfolgreich geändert',
    message: 'Nachricht',
    edit: 'Bearbeiten',
    delete: 'Löschen',
    select_categories: 'Kategorien auswählen',
    categories_name: 'Kategorien Name',
    select_product: 'Wählen Sie Produkte aus',
    ticket_successfully_created: 'Ticket erfolgreich erstellt',
    cash_pay: 'Barzahlung',
    card_pay: 'Kartenzahlung',
    guest: 'Gast',
    sign_in_to_your_account: 'Melden Sie sich bei Ihrem Konto an',
    remember_me: 'Erinnere dich an mich',
    forgot_password: 'Passwort vergessen?',
    sign_in: 'Einloggen',
    forgot_pass: 'Passwort vergessen',
    login: 'Anmeldung?',
    password_is_required: 'Passwort wird benötigt',
    password_is_too_short_should_be_5_chars_minimum:
        'Das Passwort ist zu kurz - sollte mindestens 5 Zeichen lang sein',
    password_details_sent_to_registered_email: ' Passwortdetails an registrierte E-Mail gesendet',
    menu: 'Speisekarte',
    code: 'Code',
    product_code: 'Produktcode',
    combo_deals: 'Kombi-Angebote',
    add_combo: 'Combo hinzufügen',
    combo_name: 'Combo-Name',
    offer_price: 'Angebotspreis',
    combo_amount: 'Combo-Betrag',
    edit_combo: 'Kombination bearbeiten',
    saving: 'Speichern',
    menu_schedule: 'Menüplan',
    add_schedule: 'Neuen Zeitplan hinzufügen',
    schedule_name: 'Zeitplanname',
    categories: 'Kategorien',
    days: 'Tage',
    timing: 'Zeitliche Koordinierung',
    edit_schedule: 'Zeitplan bearbeiten',
    search_categories: 'Kategorien suchen',
    veg: 'Gemüse',
    non_veg: 'Nicht vegetarisch',
    select_days: 'Wählen Sie Tage aus',
    schedule_timimg: 'Zeitplan einplanen',
    start_time: 'Startzeit',
    reset: 'Zurücksetzen',
    done: 'Fertig',
    edit_menu_schedule: 'Menüplan bearbeiten',
    add_menu_schedule: 'Menüplan hinzufügen',
    grouping_categories: 'Kategorien gruppieren',
    group_name: 'Gruppenname',
    group_cat: 'Gruppenkategorie',
    select_category: 'Kategorie wählen',
    edit_grp: 'Gruppenkategorie bearbeiten',
    table_config: 'Tabellenkonfiguration',
    table_view: 'Tabellenansicht',
    add_new_area: 'Neuen Bereich hinzufügen',
    generate_table: 'Tabellen generieren',
    special_note: 'Spezielle Notiz',
    menu_item: 'Menüpunkt',
    edit_menu: 'Menüpunkt bearbeiten',
    product: 'Produkt',
    kitchen_order_ticket: 'Küchenbestellkarte',
    table_orders: 'Tischbestellungen',
    take_aways: 'Imbiss',
    table: 'Tisch',
    main_order: 'Hauptordnung',
    return_order: 'Rückholauftrag',
    no_returned_order: 'Keine Rücksendung',
    combo_orders: 'Combo-Bestellungen',
    split_orders: 'Aufgeteilte Bestellungen',
    generic_orders: 'Generische Bestellungen',
    waiter_name: 'Name des Kellners',
    item_details: 'Artikeldetails',
    select_item: 'Menüpunkt wählen',
    kot: 'KOT',
    kot_orders: 'KOT-Befehle',
    kot_list: 'KOT-Liste',
    kot_list_are_not_available: 'KOT-Liste sind nicht verfügbar.',
    table_no: 'Tisch Nr',
    foods: 'Lebensmittel',
    pay_by: 'Zahlung per',
    total_count: 'Komplette Anzahl',
    new_category: 'Neue Kategorie',
    add_new_category: 'neue Kategorie hinzufügen',
    to_creat_new_category_click_here: 'Um eine neue Kategorie zu erstellen, klicken Sie hier',
    category_images: 'Kategorie Bilder',
    add_sub_category: 'Unterkategorie hinzufügen',
    sub_category_name: 'Name der Unterkategorie',
    edit_sub_category: 'Unterkategorie bearbeiten',
    business_type: 'Unternehmensart',
    successfully_saved: 'Erfolgreich gespeichert',
    attendance_successfully_saved: 'Anwesenheit erfolgreich gespeichert',
    packing: 'Verpackung',
    delivery_charge: 'Zustellgebühr',
    container: 'Container',
    service_charge: 'Servicegebühr',
    all_orders: 'Alle Bestellungen',
    select_status: 'Status auswählen',
    select_order_type: 'Auftragstyp auswählen',
    both: 'Beide',
    dine_in: 'Speisen in',
    take_away: 'Wegbringen',
    select_date_range: 'Wählen Sie Datumsbereich aus',
    pending: 'Ausstehend',
    grouping_category_name_is_required: 'Der Name der Gruppierungskategorie ist erforderlich',
    grouping_category_name_not_more_than_25_characters:
        'Name der Gruppierungskategorie nicht mehr als 25 Zeichen',
    table_name: 'Tabellenname',
    seating_capacity: 'Sitzplatzkapazität',
    waiter_name: 'Name des Kellners',
    table_name_is_required: 'Tabellenname ist erforderlich',
    seating_capacity_is_required: 'Sitzplatzkapazität ist erforderlich',
    waiter_name_is_required: 'Der Name des Kellners ist erforderlich',
    area_name_is_required: 'Gebietsname ist erforderlich',
    floor_is_required: 'Boden ist erforderlich',
    select_category_is_required: 'Kategorie auswählen Ist erforderlich',
    combo_name_is_required: 'Kombinationsname ist erforderlich',
    combo_name_not_more_than_25_characters: 'Kombinationsname Name nicht mehr als 25 Zeichen',
    combo_amount_is_required: 'Combo-Betrag ist erforderlich',
    select_product_is_required: 'Produkt auswählen Ist erforderlich',
    combo_deals_are_not_available_click_add_combo_to_create_new_combo:
        'Kombiangebote sind nicht verfügbar. Klicken Sie auf Combo hinzufügen, um eine neue Combo zu erstellen',
    menu_schedule_are_not_available_click_add_menu_schedule_to_create_new_menu_schedule:
        'Menüplan ist nicht verfügbar. Klicken Sie auf Menüplan hinzufügen, um einen neuen Menüplan zu erstellen',
    categories_selected: 'Kategorie ausgewählt',
    zipcode_is_required: 'Postleitzahl ist erforderlich',
    invalid_zipcode: 'Ungültige Postleitzahl',
    profile_image: 'Profilbild',

    create_area: 'Bereich erstellen',
    update_area: 'Bereich aktualisieren',
    create_table: 'Tabelle erstellen',
    update_table: 'Tabelle aktualisieren',
    filter_order: 'Filterreihenfolge',
    grouping_categories_are_not_available_click_add_category_to_create_new_grouping_category:
        'Gruppierungskategorien sind nicht verfügbar. Klicken Sie auf Gruppierungskategorien hinzufügen, um eine neue Gruppierungskategorie zu erstellen',
    grouping_category: 'Gruppierungskategorie',
    schedule_name_is_required: 'Zeitplanname ist erforderlich',
    selected_category_item_is_required:
        'Wählen Sie mindestens eine Kategorie oder einen Artikel aus',
    schedule_day_is_required: 'Plantag ist erforderlich',
    schedule_time_is_required: 'Planzeit ist erforderlich',
    all_days: 'Alle Tage',
    tip_mandatory: 'Trinkgeld Pflicht',
    emp_type: 'Mitarbeitertyp',
    email_address: 'E-Mail-Addresse',
    load_more: 'Mehr laden',
    floor: 'Boden',
    favorite: 'Favoritin',
    area_name: 'Bereichsname',
    refund: 'Erstattung',
    order_type: 'Auftragsart',
    more: 'Mehr',
    tip: 'Spitze',
    miscellaneous: 'Sonstig',
    add_miscellaneous: 'Sonstiges hinzufügen',
    edit_miscellaneous: 'Sonstiges bearbeiten',
    terms_conditions: 'Terms & amp; Bedingungen',
    update_date: 'Aktualisierungsdatum 2023',
    i_accept: 'Ich nehme an',
    accept: 'Annehmen',
    decline: 'Abfall',
    error: '404 Fehler',
    including_tax: 'Steuern eingeschlossen',
    page_not_found: 'Seite nicht gefunden.',
    go_back_home: 'Wieder nach Hause gehen',
    sorry_we_couldnt_find_the_page_youre_looking_for:
        'Entschuldigung, wir konnten die gesuchte Seite nicht finden.',
    miscellaneous_details: 'Verschiedene Details',
    add_to_list: 'Zur Liste hinzufügen',
    no_of_products: 'Anzahl der Produkte',
    add_to_category: 'Zur Kategorie hinzufügen',
    miscellaneous_are_not_available_click_add_miscellaneous_to_create_new_miscellaneous:
        'Sonstiges ist nicht vorhanden. Klicken Sie auf „Verschiedenes hinzufügen“, um ein neues „Verschiedenes“ zu erstellen',
    select_menu_items: 'Menüpunkte auswählen',
    global_category: 'Globale Kategorie',
    global_categories_are_not_available: 'Globale Kategorien sind nicht verfügbar',
    selected_category_is_mapped_to_your_business:
        'Ausgewählte Kategorie wird Ihrem Unternehmen zugeordnet',
    successfully_mapped: 'Erfolgreich zugeordnet',
    global_products: 'Globale Produkte',
    global_products_are_not_available: 'Globale Produkte sind nicht verfügbar',
    items_successfully_mapped: 'Elemente erfolgreich zugeordnet',
    upload_csv: 'Hochladen',
    barcode_is_required: 'Strichcode ist erforderlich',
    uom_title: 'UOM-Titel',
    color_code: 'Farbcode',
    color_code_is_required: 'Farbcode ist erforderlich',
    item_image: 'Artikelbild',
    sub_category: 'Unterkategorie',
    csv_to_10mb: 'csv bis 10mb',
    slot_has_been_already_exist: 'Slot ist bereits vorhanden',
    sub_categories: 'Unterkategorien',
    original_price: 'Original Preis',
    image_required: 'Bild erforderlich',
    file_size: 'Die Dateigröße sollte weniger als 2 MB betragen',
    to_creat_new_category_click_here:
        'Kategorien sind nicht verfügbar. Klicken Sie auf Kategorie hinzufügen, um eine neue Kategorie zu erstellen',
    to_create_new_sub_category_click_three_dots_from_catogry_list:
        'Unterkategorien sind nicht verfügbar. Klicken Sie in der Kategorieliste auf das Drei-Balken-Menü, um Unterkategorie hinzuzufügen.',
    to_create_area:
        'Tischboden sind nicht vorhanden. Bitten Sie den Administrator, Stockwerke hinzuzufügen.',
    sub_categories: 'Unterkategorien',
    no_floor_no_area: 'Kein Boden',
    to_remove_this_time_slot: 'Möchten Sie dieses Zeitfenster wirklich entfernen?',
    yes_remove_it: 'Ja, entfernen Sie es',
    count: 'Zählen',
    menu_items: 'Menüpunkte',
    is_required: 'ist nötig',
    qty: 'Anzahl',
    bills: 'Rechnungen',
    sales_summary_datas_are_not_available: 'Verkaufszusammenfassungsdaten sind nicht verfügbar',
    table_configuration: 'Tabellenkonfiguration',
    card: 'Karte',
    choose: 'Wählen',
    cash: 'Kasse',
    customer_name: 'Kundenname',
    highest_selling: 'Meistverkauft',
    closing: 'Schließen',
    opening: 'Öffnung',
    customer_on_board_summary_are_not_available:
        'Die Kunden-On-Board-Zusammenfassung ist nicht verfügbar',
    highest_selling_are_not_available: 'Meistverkaufte sind nicht verfügbar',
    on_off_product_image_in_pos: 'Ein/Aus-Produktbild im POS',
    enable_product_image_in_pos: 'Produktbild im POS aktivieren',
    apply_same_starting_cash_daily: 'Wenden Sie täglich das gleiche Startgeld an',
    on_off_cron: 'Ein/Aus Cron',
    csv: 'CSV'
};
