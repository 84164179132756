import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import './index.css';
import { HashRouter as Router } from 'react-router-dom';
import { ApolloProvider } from '@apollo/client';
import { client } from './app/GraphqlQuery/ApolloClient/Client';
import { I18nextProvider } from 'react-i18next';
import i18n from './translations/i18n';
import { Toaster } from 'react-hot-toast';
ReactDOM.render(_jsxs(React.StrictMode, { children: [_jsx(Router, { basename: "/", hashType: "slash", children: _jsx(I18nextProvider, { i18n: i18n, children: _jsx(ApolloProvider, { client: client, children: _jsx(App, {}) }) }) }), _jsx(Toaster, {})] }), document.getElementById('root'));
