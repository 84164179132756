/* eslint-disable no-dupe-keys */
/** @format */

export const TRANSLATIONS_TA = {
    dashboard: '&&&&&&&&',
    home: '&&',
    settings: '&&&&&',
    addons: '&&&&&&&',
    products: '&&&&&&&&&',
    categories: '&&&&&&&&&&&',
    configuration: '&&&&&&&&&&&',
    customer: '&&&&&&&',
    customer_view: '&&&&&&& &&&&&',
    customer_table: '&&&&&&&&& &&&&&',
    employee: '&&&&&&&&& &&&&&',
    employee_role: '&&&&&&& &&&&&',
    employee_type: '&&&&&&& &&&&&',
    employee_attendance: '&&&&&&& &&&&&&&&&&',
    edit_item: '&&&& &&&&',
    loyalty: '&&&&&&&&',
    loyalty_settings: '&&&&&&& &&&&&&&&&',
    coupons_redeemption: '&&&&&&& &&&&&&&&&&&',
    loyalty_points: '&&&&&&&& &&&&&&',
    items: '&&&&&',
    Add_Item: '&&&&&',
    fixed_amount: '&&&&& &&&&&',
    View_Item: '&&&&',
    Profile: '&&&&',
    payment_type: '&&&&&&&& &&&&&',
    role: '&&&&',
    tax: '&&&',
    unit: '&&&&',
    uom: '&&&',
    users: '&&&&&',
    coupon: '&&&&&',
    add_coupon: '&&&&&',
    edit_coupon: '&&&&&',
    variation: '&&&&&&&&&&&',
    report: '&&&&&&',
    pos_session: '&&& &&&&&&&&',
    update_password: '&&& &&&&&&&&',
    outlet_settings: '&&&&&& &&&&&&&&',
    outlet_details: '&&&&&&& &&&&&&&&',
    outlet_timings: '&&&&&& &&&&&&&',
    general: '&&&&&&&',
    invoice_summary: '&&&&&&&& &&&&&&',
    inventory_summary: '&&&&&&&& &&&&&&&',
    high_sales: '&&&&& &&&&&&',
    sales_summary: '&&&&& &&&&&&&',
    customer_summary: '&&&&&&& &&&&&&&',
    tax_on_off: '&&& &&/&&& ',
    item_on_off: '&&&& &&/&&&',
    available_qty: '&&&&&&&&& &&&',
    apply_discount: '&&&&&& &&&&&&&&&',
    search: '&&&&&&&',
    filter: '&&&&&&&&',
    add_item: '&&& &&&&',
    pay_type: '&&& &&&&',
    ticket_no: '&&& &&&&',
    view_ticket: '&&& &&&&',
    open_options: '&&&& &&&&&&&',
    selected: '&&&&&&&&& ',
    all: ' &&&',
    image: '&&&&&&',
    name: '&&&&',
    sku: '&&&',
    price: '&&&&&&',
    available_quantity: '&&&&&&&&&& &&&&&&&&&&&',
    actions: '&&&&&&&',
    product_details: '  &&&&&&&& &&&&&&&&',
    product_code_sku: '&&&&&&& &&&& / &&&',
    product_name: '&&&&&&& &&&&',
    brand_name: '&&&&& &&&&',
    category: '  &&&&&&&&&&',
    sale_unit: ' &&&& &&&&&',
    sale_quantity: '&&&& &&&&&&&&&&',
    barcode: '&&&&&&&&',
    uom: ' &&&',
    mark_as_favorite: ' &&&& && &&&&&&&&&&',
    age_restricted: ' &&& &&&&&&&&&&&',
    description: ' &&&&&&&&&&&',
    pricing: ' &&&&&&&&&',
    discount_type: ' &&&&&&&&& &&&&',
    percent_fixed_amount: ' &&&&&&& / &&&&& &&&&&&&',
    charge_tax_for_this_item: ' &&&&&& &&& &&& &&&& &&&&',
    images: '&&&&&&',
    stock: '&&&&&',
    minimum_quantity: ' &&&&&&&&& &&&&&&&&&',
    minimum_quantity_is_to_show_alert_when_quantity_goes_low:
        ' &&&&&&&& &&&&&&&&& && && &&&& &&&&&& &&&& &&&&&&&&& &&&& &&&',
    variant_name: '&&&&&&&& &&&& ',
    quantity: '&&&&&&&&',
    add_on: '&&& &&&',
    enable_in_pos: '&&&&&&& && &&&',
    selected_a_tab: ' &&&&&&&&& & &&&',
    clear: ' &&&&&',
    update: '&&&&&&&',
    save: '&&&&',
    add: '&&&',
    product_code_is_required: '&&&&&&&& &&&& && &&&&&&&&',
    product_name_is_required: '&&&&&&& &&&& && &&&&&&&&&',
    sale_quantity_is_required: '&&&& &&&&&&&& && &&&&&&&&&',
    select_category_is_required: '&&&&&&& &&&&&&&&& && &&&&&&&&&',
    select_sub_category_is_required: '&&&&&&& &&&&&&&&& && &&&&&&&&&',
    price_is_required: '&&&&&& && &&&&&&&&&',
    minimum_quantity_is_required: '&&&&&&&&& &&&&&&&&& && &&&&&&&&&',
    available_quantity_is_required: '&&&&&&&&& &&&&&&&&&& && &&&&&&&&&&',
    brand_name_is_required: '&&&&&& &&&& && &&&&&&&&&',
    enter_category_name: '&&&&& &&&&&&&&& &&&&',
    page_size: '&&&& &&&&',
    next: ' &&&&',
    previous: '&&&&&&&&&&',
    showing: '&&&&&&&&',
    to: '&&',
    of: ' &&',
    results: ' &&&&&&&&',
    option_1: '&&&&&&& &',
    option_2: '&&&&&&& &',
    apply_tax: '&&&&& &&&',
    apply: ' &&&&&',
    cancel: '&&&&&&',
    note: ' &&&&',
    set_to_on_if_you_want_to_charge_tax_for_this_item_in_pos_set_to_off_if_you_do_not_want_to_charge_tax_for_this_item_in_pos:
        '&&& && && && &&& &&&& && &&&&&&& &&& &&& &&&& &&&& && &&&. &&& && &&&, && &&& && &&& &&&& && &&&&&& &&& &&& &&&& &&&& && &&&.',
    set_to_off_if_you_do_not_want_to_show_this_item_in_pos_set_to_on_if_you_want_to_show_this_item_in_pos:
        '&&& && &&& && &&& && &&& &&&& && &&&& &&&& &&&& && &&&. &&& && &&, && &&& &&&& && &&&& &&&& &&&& && &&&.',
    this_quantity_will_be_added_to_the_already_available_quantity_eg_if_there_5_products_in_inventory_and_available_quantity_applied_is_100_after_this_operation_available_quantity_becomes_105:
        ' &&&& &&&&&&&& &&&& && &&&&&& && &&& &&&&&& &&&&&&&&&& &&&&&&&&. &.&.&& &&&&& 5 &&&&&&&& && &&&&&&&& &&& &&&&&&&&&&&& &&&&&&&&& &&&&&&&& && &&&, &&&&&& &&&& &&&&&&&&&& &&&&&&&&&&& &&&&&&&&& &&&&&&&& 105.',
    discount_is_applied_by_percentage_only: '&&&&&&&&&&& && &&&&&&& && &&&&&&&&&&&& &&&&.',
    yes_delete_it: '&&&, &&&&&&& &&',
    yes: '&&&',
    no: '&&',
    add_customer: '&&& &&&&&&&&&&',
    first_name: '  &&&&& &&&&',
    last_name: '  &&&& &&&&',
    mobile: ' &&&&&&',
    date_of_birth: ' &&&& && &&&&&',
    email: '&&&&&&',
    area: ' &&&&',
    browse_photo: ' &&&&&&& &&&&&',
    click_to_add_profile_picture: ' &&&&&& && &&& &&&&&&& &&&&&&&&',
    zipcode: ' &&&&&&&&&',
    tax_number: ' &&& &&&&&&&',
    address_1: '  &&&&&&&& &',
    do_not_disturb: ' && &&& &&&&&&&',
    first_name_is_required: '&&&&& &&&& && &&&&&&&&&',
    first_name_not_more_than_25_characters: '&&&&& &&&& &&& &&&& &&&& && &&&&&&&&&&&',
    last_name_is_required: '&&&& &&&& && &&&&&&&&&',
    last_name_not_more_than_25_characters: '&&&& &&&& &&& &&&& &&&& && &&&&&&&&&&',
    email_not_valid: '&&&&& &&& &&&&&&',
    email_is_required: '&&&&&& && &&&&&&&&',
    mobile_is_required: '&&&&&& && &&&&&&&&&',
    mobile_number_is_required: '&&&&&& &&&&&& && &&&&&&&&',
    mobile_not_more_than_12_numbers: '&&&&&& &&& &&&& &&&& && &&&&&&&&',
    mobile_number_not_more_than_15_characters: '&&&&&& &&&&&& &&& &&&& &&&& && &&&&&&&&&&',
    address_1_not_more_than_150_characters: '&&&&&&&& 1 &&& &&&& &&&& &&& &&&&&&&&&&',
    zip_not_more_than_10_characters: '&&& &&& &&&& &&&& && &&&&&&&&',
    close_panel: ' &&&&& &&&&&',
    birthday: ' &&&&&&&&&',
    address_2: '&&&&&&&& &',
    add_category: '&&& &&&&&&&&&',
    category_name: '&&&&&&&& &&&&',
    on_off: ' &&/&&&',
    tag_name: ' &&& &&&&',
    category_image: '&&&&&&&&& &&&&&',
    upload_from_device: ' &&&&&& &&&& &&&&&&',
    upload_from_repository: '&&&&&& &&&& &&&&&&&&&&&&',
    category_name_is_required: '&&&&&&&&& &&&& && &&&&&&&&',
    category_name_not_more_than_50_characters: '&&&&&&&&& &&&& &&& &&&& &&&& 50 &&&&&&&&&&',
    tag_name_is_required: '&&& &&&& && &&&&&&&&&',
    edit_category: '&&&& &&&&&&&&&',
    sales_trend: '&&&&& &&&&&',
    inventory: ' &&&&&&&&&&',
    highest_selling_items: ' &&&&&&&& &&&&&&&&& &&&&&',
    business_name: ' &&&&&&&&&& &&&&',
    shop_name: '&&&& &&&&',
    business_owner: ' &&&&&&&& &&&&&',
    owner_mobile: ' &&&&& &&&&&&',
    owner_email: '&&&&& &&&&&',
    outlets: ' &&&&&&&',
    want_to_add_outlet: '&&&& && &&& &&&&&&',
    click_here: '&&&&& &&&&',
    common_setting: '  &&&&&& &&&&&&&&',
    language: ' &&&&&&&&',
    currency: '&&&&&&&&',
    notification: ' &&&&&&&&&&&',
    daily_sales_report: '&&&&& &&&&& &&&&&&',
    send_daily_sales_report_from_all_outlets_in_one_email:
        '&&&& &&&&& &&&&& &&&&&& &&&& &&& &&&&&&& && &&& &&&&&',
    send_monthly_annual_sale_report_from_all_outlets_in_one_email:
        '&&&& &&&&& &&&&& &&&&&& &&&& &&& &&&&&&& && &&& &&&&&',
    send_inventory_alert_report_from_all_outlets_in_one_email:
        '&&&& &&&&& &&&&& &&&&&& &&&& &&& &&&&&&& && &&& &&&&&',
    send_monthly_customer_data_report_from_all_outlets_in_one_email:
        '&&&& &&&&& &&&&& &&&&&& &&&& &&& &&&&&&& && &&& &&&&&',
    monthly_annual_sale_report: '&&&&&&& &&&&&& &&&& &&&&&&',
    inventory_alert: '&&&&&&&& &&&&&',
    monthly_customer_data: '&&&&&&& &&&&&&& &&&&',
    use_setting: '&&& &&&&&&&',
    tax_details: '&&& &&&&&&&',
    tip_handle: '&&& &&&&&&',
    manual: '&&&&&&',
    auto: '&&&&',
    edit_cover: ' &&&& &&&&&',
    contact_details: ' &&&&&&& &&&&&&&',
    tax_master: '&&& &&&&&&',
    employment_types: '&&&&&&&&& &&&&&',
    add_tax: '&&& &&&',
    add_variation: '&&& &&&&&&&&&',
    add_addon: '&&& &&&&&',
    add_uom: '&&& &&&',
    add_unit: '&&& &&&&',
    add_employment_type: '&&& &&&&&&&&&& &&&&',
    add_employment_role: '&&& &&&&&&&&&& &&&&',
    add_payment_type: '&&& &&&&&&& &&&&',
    open_time: '&&&& &&&&',
    close_time: ' &&&&& &&&&',
    day_of_week: '&&& && &&&&',
    leave: '&&&&&',
    add_user: '&&& &&&&',
    add_role: '&&& &&&&',
    add_employee: '&&& &&&&&&&&',
    id: '&&',
    phone_no: '&&&&& &&',
    type: '&&&&',
    employee_name: '&&&&&&&& &&&&',
    employee_id: '&&&&&&&& &&',
    date_of_joining: ' &&&& && &&&&&&&&',
    date_of_leaving: ' &&&& && &&&&&&&&',
    payment: ' &&&&&&&&',
    employee_name_is_required: '&&&&&&&& &&&& && &&&&&&&&',
    employee_name_not_more_than_25_characters: '&&&&&&&& &&&& &&& &&&& &&&& 25 &&&&&&&&&&',
    employee_id_is_required: '&&&&&&&& && && &&&&&&&&&',
    employment_type_is_required: '&&&&&&&&&& &&&& && &&&&&&&&',
    role_is_required: '&&&&  && &&&&&&&',
    payment_is_required: '&&&&&&& && &&&&&&&',
    phone_no_is_required: '&&&&& && && &&&&&&&&',
    address: ' &&&&&&&',
    session_id: '&&&&&&& &&',
    opening_date: '&&&&&&& &&&&',
    closing_date: '&&&&&&& &&&&',
    status: '&&&&&&&',
    orders: '&&&&&&',
    amount: `&&&&&&&&`,
    session: '  &&&&&&&&',
    order_no: '&&&&& &&',
    order_type: `&&&&& &&&& `,
    order_value_$: `&&&&& &&&&&`,
    coupon_name: '&&&&&& &&&&',
    coupon_code: '&&&&&& &&&&',
    valid_date: '&&&&& &&&&',
    redemption: '&&&&&&&&&&',
    valid_till: '&&&&& &&&&',
    percent: '&&&&&&&',
    coupon_users: '&&&&&& &&&&&',
    back: '&&&&',
    edit_coupon_details: '&&&& &&&&&& &&&&&&&',
    add_coupon_details: '&&& &&&&&& &&&&&&&',
    apply_discount_after_tax: '&&&&& &&&&&&&&& &&&&& &&&',
    valid_from: '&&&&& &&&&',
    coupon_details: '&&&&&& &&&&&&&',
    target_audience: '&&&&&& &&&&&&&&',
    favorites: '&&&&&&&&&',
    finish: '&&&&&&',
    coupon_name_is_required: '&&&&&& &&&& && &&&&&&&&',
    coupon_code_is_required: '&&&&&& &&&& && &&&&&&&&',
    discount_type_is_required: '&&&&&&&& &&&& && &&&&&&&&',
    percent_is_required: '&&&&&&& && &&&&&&&&',
    reedem_points: '&&&&&& &&&&&&',
    value: '&&&&&',
    loyalty_points: '&&&&&&& &&&&&&',
    total_loyalty: '&&&&& &&&&&&&',
    order_value_d: '&&&&& &&&&&',
    in_progress: '&& &&&&&&&&',
    settled: ' &&&&&&&',
    cancelled: ' &&&&&&&&&',
    returned: ' &&&&&&&&',
    biller: '  &&&&&&',
    order_status: ' &&&&& &&&&&&',
    card_details: '  &&&& &&&&&&&',
    customer_details: '&&&&&&& &&&&&&&',
    paid: '   &&&&',
    date: '    &&&&',
    time: ' &&&&',
    discount: '&&&&&&& &',
    tax: '&&&',
    grand_total: ' &&&&& &&&&&',
    total: ' &&&&&',
    item_name: '&&&& &&&&',
    unit_price_$: '&&&& &&&&& &',
    total_price_$: '&&&&& &&&&& &',
    business_image: ' &&&&&&& &&&&&',
    upload: ' &&&&&&',
    invoice_no: '&&&&&& &&',
    payment: '&&&&&&&',
    welcome_to: '&&&&&&& &&',
    providing_affordable_one_step_payment_solutions_across_the_globe_to_support_growing_businesses:
        '&&&&&&&&& &&&&&&&& &&&-&&&& &&&&&&& &&&&&&&&& &&&&&& &&& &&&&& && &&&&&& &&&&&&& &&&&&&&&&&',
    loading: '&&&&&&&',
    employees: '&&&&&&&&&',
    filter_category: '&&&&&& &&&&&&&&',
    order_details: '&&&&& &&&&&&&',
    edit_customer: '&&&& &&&&&&&',
    edit_business: '&&&& &&&&&&&&',
    created_date: '&&&&&&& &&&&',
    edit_user: '&&&& &&&&',
    edit_role: '&&&& &&&&',
    view_coupon: '&&&& &&&&&&',
    open_time_is_required: '&&&& &&&& && &&&&&&&',
    close_time_is_required: '&&&&& &&&& && &&&&&&&',
    event_is_required: '&&&&& && &&&&&&&&',
    redemption_point_is_required: '&&&&&&&&&& &&&&& && &&&&&&&&',
    condition_is_required: '&&&&&&&&& && &&&&&&&&',
    value_is_required: '&&&&& && &&&&&&&&',
    points_is_required: '&&&&&& && &&&&&&&&',
    loyalty_empty: '&&&&&&& &&&&&',
    do_you_want_to_enable_loyalty_for_your_outlet: ' && &&& &&&& && &&&&&& &&&&&&& &&& &&&& &&&&&&',
    enable_loyalty: '&&&&&& &&&&&&&',
    point_setting: '&&&&& &&&&&&&&',
    points: '&&&&&&',
    value: '&&&&&',
    points_equals: ' &&&&&& &&&&&&',
    reward_rules: '&&&&&& &&&&&',
    event: '&&&&&',
    condition: ' &&&&&&&&&',
    order_value: ' &&&&& &&&&&',
    redemption_rules: '&&&&&&&&&& &&&&&',
    customer_can_redeem_when: '&&&&&&&& &&& &&&&&& &&&&',
    profile_details: ' &&&&&&& &&&&&&&',
    edit_profile: ' &&&& &&&&&&&',
    contact_number: ' &&&&&&& &&&&&&',
    change_password: ' &&&&&& &&&&&&&',
    password: '&&&&&&&&',
    reset_password: ' &&&&& &&&&&&&',
    current_password_is_required: '&&&&&&& &&&&&&&& && &&&&&&&&',
    current_password_not_match: '&&&&&&& &&&&&&&& &&& &&&&&',
    new_password_is_required: '&&& &&&&&&&& && &&&&&&&&',
    new_password_is_too_short_should_be_4_chars_minimum:
        '&&& &&&&&&&& && &&& &&&&& - &&&&&& && 4 &&&&& &&&&&&&',
    new_password_is_too_long_should_be_6_chars_maximum: '&&& &&& &&& &&& 6 &&&&& &&',
    confirm_password_is_required: '&&&&&&& &&&&&&&& && &&&&&&&&',
    passwords_must_match: '&&&&&&&&& &&&& &&&&&',
    current_password: '&&&&&&& &&&&&&&&',
    new_password: '&&& &&&&&&&&',
    confirm_password: '&&&&&&& &&&&&&&&',
    update_profile: '&&&&&& &&&&&&&',
    change: '&&&&&&',
    coupen_code: '&&&&&& &&&&',
    discount_value: '&&&&&&& &&&&&&',
    installation: '&&&&&&&&&&&&',
    pos_installation: '&&&&&&&& &&&&&&&&&&&&',
    pos_for_windows: '&&& &&& &&&&&&&',
    pos_for_mac: '&&& &&& &&&',
    download: '&&&&&&&&',
    installation_for_pos: '&&&&&&&&&&&& &&& &&&',
    windows: '&&&&&&&',
    mac: '&&&',
    you_are_seeing: '&&& &&& &&&&&& ',
    last_one_week: '&&&& &&& &&&&',
    data: '&&&&',
    tracking_issues: '&&&&&&&& &&&&&&',
    new_ticket: '&&& &&&&&&',
    tickets_list: '&&&&&&& &&&&',
    outlet_name: '&&&&&& &&&&',
    title: '&&&&&',
    screen_name: '&&&&&& &&&&',
    seviority: '&&&&&&&&&',
    priority: '&&&&&&&&&&',
    description: '&&&&&&&&&&&&',
    file_attachments: '&&&& &&&&&&&&&&&&',
    discard: '&&&&&&&&',
    defect_id: '&&&&&& &&',
    status: '&&&&&&',
    action: '&&&&&&',
    defect_no: '&&&&&& &&',
    attachments: '&&&&&&&&&&&',
    download: '&&&&&&&&',
    create: '&&&&&&',
    image_upload_from_device_or_from_repository: '&&&&& &&&&&& &&&& &&&&&& && &&&& &&&&&&&&&&',
    total_bills: '&&&&& &&&&&',
    returned_bills: '&&&&&&&& &&&&&',
    settled_bills: '&&&&&&&& &&&&&',
    cancelled_bills: '&&&&&&&&& &&&&&',
    total_amount: '&&&&& &&&&&&',
    sales_amount: '&&&&& &&&&&&',

    discount: '&&&&&&&&',
    card_payments: '&&&& &&&&&&&&',
    cash_payment: '&&&& &&&&&&&',
    total_sales: '&&&&& &&&&&',
    total_tax: '&&&&& &&&',
    select_uom_is_required: '&&&&&&& &&& && &&&&&&&&',
    isbn_upc_gtin_etc: '&&&&, &&&, &&&&, &&&',
    price: ' &&&&&',
    upload_a_file: '&&&&&& & &&&&',
    png_jpg_gif_up_to_10mb: '&&&, &&&, &&& && && 10&&',
    enter_minimum_quantity: '&&&&& &&&&&&& &&&&&&&&',
    enter_available_quantity: '&&&&& &&&&&&&&& &&&&&&&&',
    item: '&&&&',
    apply_qty: '&&&&& &&&',
    discount_per: '&&&&&&&(%)',
    are_you_sure: '&&& &&& &&&&?',
    do_you_really_want_to_delete: '&& &&& &&&&&& &&&& && &&&&&&',
    expences: '&&&&&&&',
    products_assigned_to_the: '&&&&&&& &&&&&&& && &&&',
    category_will_be_deleted_do_you_want_to_proceed:
        '&&&&&&& &&& && &&&&&&&. && &&& &&&& && &&&&&&?',
    customers_are_not_available_click_add_customer_to_create_new_customer:
        '&&&&&&&& &&& &&& &&&&&&&&&&. &&&&& &&& &&&&&&&& && &&&&&& &&& &&&&&&&&',
    categories_are_not_available_click_add_category_to_create_new_category:
        '&&&&&&&&&& &&& &&& &&&&&&&&&&. &&&&& &&& &&&&&&&& && &&&&&& &&& &&&&&&&&',
    coupons_are_not_available_click_add_coupon_to_create_new_coupon:
        '&&&&&&& &&& &&& &&&&&&&&. &&&&& &&& &&&&&& && &&&&&& &&& &&&&&&',
    employee_are_not_available_click_add_employee_to_create_new_employee:
        '&&&&&&& &&& &&& &&&&&&&&. &&&&& &&& &&&&&& && &&&&&& &&& &&&&&&',
    employment_role_are_not_available_click_add_employment_role_to_create_new_employment_role:
        '&&&&&&&&&& &&&& &&& &&& &&&&&&&&. &&&&& &&& &&&&&&&&&& &&&& && &&&&&& &&& &&&&&&&&&& &&&&',
    employment_type_are_not_available_click_add_employment_type_to_create_new_employment_type:
        '&&&&&&&&&& &&&& &&& &&& &&&&&&&&. &&&&& &&& &&&&&&&&&& &&&& && &&&&&& &&& &&&&&&&&&& &&&&',
    items_are_not_available_click_add_item_to_create_new_item:
        '&&&&& &&& &&& &&&&&&&&. &&&&& &&& &&&& && &&&&&& &&& &&&&',
    orders_are_not_available: '&&&&&& &&& &&& &&&&&&&&&.',
    loyalties_are_not_available: '&&&&&&&&& &&& &&& &&&&&&&&.',
    payment_type_are_not_available_click_add_payment_type_to_create_new_payment_type:
        '&&&&&&&& &&&& &&& &&& &&&&&&&&&. &&&&& &&& &&&&&&& &&&& && &&&&&& &&& &&&&&&& &&&&',
    sales_summary_are_not_available: '&&&&& &&&&&&& &&& &&& &&&&&&&&&.',
    tax_are_not_available_click_add_tax_to_create_new_tax:
        '&&& &&& &&& &&&&&&&&. &&&&& &&& &&& && &&&&&& &&& &&&',
    unit_are_not_available_click_add_unit_to_create_new_unit:
        '&&&& &&& &&& &&&&&&&&. &&&&& &&& &&&& && &&&&&& &&& &&&&',
    uom_are_not_available_click_add_uom_to_create_new_uom:
        '&&& &&& &&& &&&&&&&&. &&&&& &&& &&& && &&&&&& &&& &&&',
    user_are_not_available_click_add_user_to_create_new_user:
        '&&&& &&& &&& &&&&&&&&. &&&&& &&& &&&& && &&&&&& &&& &&&&',
    variation_are_not_available_click_add_variation_to_create_new_variation:
        '&&&&&&&& &&& &&& &&&&&&&&. &&&&& &&& &&&&&&&& && &&&&&& &&& &&&&&&&&&',
    tickets_are_not_available_click_add_tickets_to_create_new_tickets:
        '&&&&&&&& &&& &&& &&&&&&&&. &&&&& &&& &&&&&&&& && &&&&&& &&& &&&&&&&&&',
    addon_are_not_available_click_add_addon_to_create_new_addon:
        '&&&&& &&& &&& &&&&&&&&. &&&&& &&& &&&&& && &&&&&& &&& &&&&&',
    product_details_are_not_available_click_select_products_or_select_categories:
        '&&&&&&& &&&&&& &&& &&& &&&&&&&&&. &&&&& &&&&&& &&&&&&& && &&&&&& &&&&&&&&&&',
    roles_are_not_available_click_add_role_to_create_new_role:
        '&&& &&& &&& &&&&&&&&. &&&&& &&& &&& && &&&&&& &&& &&&',
    redemption_rules_are_not_available_click_add_to_create_new_redemption_rules:
        '&&&&&&&&& &&&&& &&& &&& &&&&&&&&&. &&&&& &&& && &&&&&& &&& &&&&&&&&&& &&&&&',
    tax_name: '&&& &&&&',
    tax_label: '&&& &&&&&',
    tax_percentage: '&&& &&&&&&&&&& (%)',
    variation_name: '&&&&&&&&& &&&&',
    addon_name: '&&&&& &&&&',
    uom_name: '&&& &&&&',
    unit_name: '&&&& &&&&',
    role_name: '&&&& &&&&',
    display_name: '&&&&&&& &&&&',
    created_date: '&&&&&&& &&&&',
    payment_name: '&&&&&&& &&&&',
    allow_pos_login: '&&&&& &&& &&&&&',
    roles: '&&&&&',
    role_permissions: '&&&& &&&&&&&&&&',
    edit_payment_type: '&&&& &&&&&&& &&&&',
    closing_date_time: '&&&&&&& &&&& & &&&&',
    open_date_time: '&&&& &&&& & &&&&',
    view_pos_session: '&&&& &&& &&&&&&&',
    view_employee: '&&&& &&&&&&&&',
    edit_employee: '&&&& &&&&&&&&',
    timing_same_on_all_days: '&&&&&& &&&& && &&& &&&&',
    timing_same_on_all_week_days: '&&&&&& &&&& && &&& &&&& &&&&',
    different_on_all_days: '&&&&&&&&&& && &&& &&&&',
    net_sales: '&&& &&&&&',
    expenses: '&&&&&&&&',
    start_date: ' &&&&& &&&&',
    end_date: ' &&& &&&&',
    not_exceeded_than_5_digits: '&&& &&&&&&&& &&&& & &&&&&&',
    edit_variation: '&&&& &&&&&&&&&',
    edit_addon: '&&&& &&&&&',
    edit_uom: '&&&& &&&',
    edit_unit: '&&&& &&&&',
    edit_employment_type: '&&&& &&&&&&&&&& &&&&',
    edit_employment_role: '&&&& &&&&&&&&&& &&&&',
    tax_name_lable: '&&& &&&& / &&&&&',
    coupon_redemption_are_not_available: '&&&&&& &&& &&& &&&&&&&',
    session: '&&&&&&',
    coupon_loyalty: ' &&&&& / &&&&&',
    product_details: '&&&&& &&&&&',
    current: '&&&&&',
    upcoming: '&&&&&&',
    step_1: '&&&& 1',
    step_2: '&&&& 2',
    step_3: '&&&& 3',
    edit_tax: '&&&& &&&',
    update_rule: '&&&&& &&&&',
    save_rule: '&&&& &&&&',
    filter_by: '&&&&& &&',
    export_csv: '&&&&& &&&',
    settled_count: '&&&&&& &&&&',
    return_count: '&&&&& &&&&&',
    cancelled: '&&&&&&&',
    bill_amount: '&&&& &&&&&',
    settled_amount: '&&&&& &&&&&',
    return_amount: '&&&& &&&&',
    cancel_amount: '&&&&& &&&&&',
    terminal_name: '&&&&&',
    opening_date_time: '&&&& &&& &&&&&',
    billed_by: '&&&&& &&',
    phone: '&&&&&',
    new_customer_summary: '&&& &&&&&& &&&&&&',
    customer_id: '&&&&&&& &&',
    customer_on_board_summary: '&&&&&& &&-&&&&& &&&&&&',
    card_amount: '&&&& &&&&&',
    cash_amount: '&&&& &&&&&',
    expenses_amount: '&&&&&& &&&&&&',
    tax_amount: '&&& &&&&&&',
    out_of_stocks: '&&& && &&&&&',
    instock_report_summary: '&&&&& &&&&& &&&&&&',
    quantity_available: '&&&&& &&&&&&&&',
    coupon_discount: '&&&& &&&&&&',
    coupons: '&&&&&&',
    title_is_required: '&&&&& && &&&&&&',
    title_not_more_than_25_characters: '&&&&& &&&& &&&&&& &&&& 25 &&&&&&',
    priority_is_required: '&&&&&&& && &&&&&&',
    description_is_required: '&&&&&&&& && &&&&&&&&&',
    description_not_more_than_150_characters: '&&&&&&&&&& &&& &&& &&&& 150 &&&&&&&&',
    no_reporsitory_image: '&& &&&&&&&&&&& &&&&&',
    business_information: '&&&&&&& &&&&&&',
    click_on: '&&&& && ',
    outlet_to: '&&&&& &&',
    view_and_update_settings: '&&& && &&&& &&&&&',
    for_this_outlet: '&& &&& &&&&&',
    product_code_is_not_less_than: '&&&&&& &&&& && &&& &&&& &&&&',
    character: '&&&&&&',
    product_code_is_not_more_than: '&&&&& &&&& && &&& &&&& &&&&',
    product_name_is_not_more_than: '&&&&& &&&& && &&& &&&& &&&&',
    product_name_is_not_less_than: '&&&&& &&&& && &&& &&&& &&&&',
    select_all: '&&&&&& &&&',
    terms_conditions: '&&&&& & &&&&&&&&&&',
    privacy_policy: '&&&&&&& &&&&&&',
    no_of_sold: '&& && &&&&',
    no_category_displayed: '&& &&&&&&&& &&&&&&&&',
    start_scan: '&&&&& &&&&',
    percentage: '&&&&&&&&&&&&',
    select: '&&& ',
    on: '&&',
    off: '&&&',
    anniversary: '&&&',
    dob: '&&& ',
    return: '&&&&',
    in_stock: '&& &&&&',
    total_customers: '&&& &&&&&&&&',
    new_customers: '&&& &&&&&&',
    view: '&&&&',
    click_on_the_eezzypos_for_windows_on_the_pos_till_machine_this_downloads_a_zip_file_on_the_machine:
        '&&& && && && && &&& && && &&&/&&&',
    unzip_the_file_and_click_on_epos_exe_file_to_run_the_installation_for_pos:
        '&&& && && && && &&& && && &&&/&&&',
    once_the_installation_is_complete_you_can_launch_the_application_from_program_files_application_name_is_eezzypos:
        '&&& && && && && &&& && && &&&/&&&',
    launch_the_eezzypos_application_to_access_the_pos_for_billing_purpose_login_and_use_the_billing_pos_application:
        '&&& && && && && &&& && && &&&/&&&',
    click_on_the_eezzypos_for_mac_on_the_pos_till_machine_this_downloads_a_zip_file_on_the_machine:
        '&&& && && && && &&& && && &&&/&&&',
    unzip_the_file_and_click_on_epos_dmg_file_to_run_the_installation_for_pos:
        '&&& && && && && &&& && && &&&/&&&',
    greater_than: '&&&& &&&',
    less_than: '&&& &&&',
    step: '&&&&',
    upi_payment: '&&&&& &&&&&&&&',
    upi: '&&&',
    on_off_Start_Cash: '&&&/&&& &&&& &&&& ',
    browse: '&&&&&&&&&',
    successfully_created: '&&&&&& &&&&',
    items_successfully_created: '&&&& &&&&& &&&&&&',
    coupon_successfully_created: '&&&&& &&&&&& &&&&&',
    category_successfully_created: '&&&&& &&&&& &&&&&',
    profile_successfully_updated: '&&&&& &&&&& &&&&&',
    successfully_changed: '&&&&&& &&&&',
    successfully_updated: '&&&&&& &&&&',
    password_successfully_changed: '&&&&& &&&&& &&&&&',
    message: '&&&&&&',
    edit: '&&&',
    delete: '&&&&',
    select_categories: '&&&& &&&&&',
    categories_name: '&&&& &&&',
    select_product: '&&&& &&&',
    ticket_successfully_created: '&&&& &&&&& &&&&&&',
    cash_pay: '&&&& &&&',
    card_pay: '&&&& &&&',
    guest: '&&&&&',
    sign_in_to_your_account: '&&&& && && &&&& &&&&&',
    remember_me: '&&&&&& &&',
    forgot_password: '&&&& &&&&?',
    sign_in: '&&&& &&',
    forgot_pass: '&&&& &&&&',
    login: '&&&&?',
    password_is_required: '&&&& && &&&&&',
    password_is_too_short_should_be_5_chars_minimum: '&&&&& && &&& &&&& - &&&&& && 5 &&&& &&&&&&',
    password_details_sent_to_registered_email: ' &&&&&& &&&& &&& && &&&&&&&& &&&&',
    menu: '&&&&',
    code: '&&&&',
    product_code: '&&&&&& &&&&',
    combo_deals: '&&&& &&&&',
    add_combo: '&&& &&&&',
    combo_name: '&&&& &&&',
    offer_price: '&&& &&&',
    combo_amount: '&&&& &&&',
    edit_combo: '&&& &&&&',
    saving: '&&&&&',
    menu_schedule: '&&&& &&&&&',
    add_schedule: '&&& &&&&&',
    schedule_name: '&&&&& &&&&',
    categories: '&&&&&',
    days: '&&&',
    timing: '&&&&&',
    edit_schedule: '&&&& &&&&&&',
    search_categories: '&&&&& &&&&&&',
    veg: '&&&',
    non_veg: '&&-&&',
    select_days: '&&&& &&&&',
    schedule_timimg: '&&&& &&&&',
    start_time: '&&& &&&',
    reset: '&&&',
    done: '&&&',
    edit_menu_schedule: '&&& &&& &&&&',
    add_menu_schedule: '&&& &&& &&&&',
    grouping_categories: '&&&& &&&&',
    group_name: '&&& &&',
    group_cat: '&&& &&&',
    select_category: '&&&& &&&',
    edit_grp: '&& && &&',
    table_config: '&&& &&&&&&',
    table_view: '&&& &&&',
    add_new_area: '&& &&& &&&',
    generate_table: '&&&& &&&',
    special_note: '&&& &&&',
    menu_item: '&&& &&',
    edit_menu: '&&& &&&&',
    product: '&&&',
    kitchen_order_ticket: '&&&&&&& &&&&& &&&&&&',
    table_orders: '&&&&& &&&&&&',
    take_aways: '&&&& &&&&&',
    table: '&&&&&',
    main_order: '&&& &&&',
    return_order: '&&& &&&',
    no_returned_order: '&& &&& &&&',
    combo_orders: '&& &&',
    split_orders: '&& &&',
    generic_orders: '&& &&',
    waiter_name: '&& &&',
    item_details: '&& &&',
    select_item: '&& &&',
    kot: '&&',
    kot_orders: '&& &&&',
    kot_list: '&& &&',
    kot_list_are_not_available: '&& && && && &&&&.',
    table_no: '&& &&',
    foods: '&&',
    pay_by: '&& &&',
    total_count: '&& &&',
    add_category: '&&& &&&&&&',
    add_new_category: '&&& &&& &&&&&&',
    to_creat_new_category_click_here: '&& &&&& &&&&& &&& &&&& &&&',
    category_images: '&& &&&&',
    add_sub_category: '&&& &&& &&&&&&',
    sub_category_name: '&&& &&& &&&&&&',
    edit_sub_category: '&&& &&& &&&&&&',
    business_type: '&&& &&&',
    successfully_saved: '&&& &&&',
    attendance_successfully_saved: '&&& &&& &&',
    packing: '&&',
    delivery_charge: '&& &&',
    container: '&&',
    service_charge: '&& &&',
    all_orders: '&& &&',
    select_status: '&&& &&&',
    select_order_type: '&& &&& &&',
    both: '&&',
    dine_in: '&& &&',
    take_away: '&& &&',
    select_date_range: '&& && &&',
    pending: '&&',
    grouping_category_name_is_required: '&& && && && &&&',
    grouping_category_name_not_more_than_25_characters: '&&& &&& &&& & && && 25 &&',
    table_name: '&&&&& &&&&',
    seating_capacity: '&&&&&& &&&&&',
    waiter_name: '&&&&&& &&&&',
    table_name_is_required: '&&&&&&& &&& && &&&&&&&&',
    seating_capacity_is_required: '&&&&&&& &&& && &&&&&&&&',
    waiter_name_is_required: '&&&&&&& &&& && &&&&&&&&',
    area_name_is_required: '&&&&&&& &&& && &&&&&&&&',
    floor_is_required: '&&&&&&& &&& && &&&&&&&&',
    select_category_is_required: '&& && && && &&&',
    combo_name_is_required: '&& && && && &&&',
    combo_name_not_more_than_25_characters: '&&& &&& &&& & && && 25 &&',
    combo_amount_is_required: '&& && && && &&&',
    select_product_is_required: '&& && && && &&&',
    combo_deals_are_not_available_click_add_combo_to_create_new_combo:
        '&&&&&&&&&& &&& &&& &&&&&&&&&&. &&&&& &&& &&&&&&&& && &&&&&& &&& &&&&&&&&',
    categories_selected: '&& &&',
    zipcode_is_required: '&&&&&&& && &&&&&&&&',
    invalid_zipcode: '&&&&&&& &&&&&&&',
    profile_image: '&&&&&&& &&&&&',
    are_you_sure_to_delete_this_combo_deal: '&& &&& &&&& && &&&&&& &&&& &&&&& &&&&',
    create_area: '&& &&',
    update_area: '&& &&',
    create_table: '&& &&',
    update_table: '&& &&',
    grouping_categories_are_not_available_click_add_category_to_create_new_grouping_category:
        '&&&&&&&&&& &&& &&& &&&&&&&&&&. &&&&& &&& &&&&&&&& && &&&&&& &&& &&&&&&&&',
    grouping_category: '&&&&&&& &&&&&&&&',
    schedule_name_is_required: '&&&&&&& &&&&& && &&&&&&&',
    selected_category_item_is_required: '&&&&& &&&& &&&& &&&&&&',
    schedule_day_is_required: '&&&&& &&& &&&&& &&&&&',
    schedule_time_is_required: '&&&&& &&&& &&&&&',
    all_days: '&& &&',
    tip_mandatory: '&& &&',
    ac_amount_updated: '&&&& &&&& &&&& &&&&&',
    emp_type: '&& &&',
    successfully_added: '&&&&&& &&&&&&&&',
    email_address: '&& &&',
    load_more: '&& &&',
    floor: '&&',
    favorite: '&&',
    area_name: '&& &&',
    refund: '&&',
    order_type: '&&&& &&&',
    more: '&&&&',
    tips: '&&&&',
    miscellaneous: '&&&&&&&&&&&',
    add_miscellaneous: '&&&&&&&&&&&',
    edit_miscellaneous: '&&&& &&&&&&&',
    terms_conditions: '&&&&& & &&&&&&&&&',
    update_date: '&&&&&& &&&& 2023',
    i_accept: '& &&&&&&',
    accept: '&&&&&&',
    decline: '&&&&&&&',
    error: '404 &&&&&',
    including_tax: '&&&&&&&&& &&&',
    page_not_found: '&&&& &&& &&&&&.',
    choose_as_Ac: '&&&& &&& &&&',
    go_back_home: '&& &&&& &&&&',
    sorry_we_couldnt_find_the_page_youre_looking_for:
        '&&&& &&& &&&&&&& &&&&& &&& &&&& &&&&&&& &&&&&&& &&&.',
    miscellaneous_details: '&&&&&&&& &&&&&&',
    add_to_list: '&& && &&',
    no_of_products: '&& && &&',
    add_to_category: '&& && &&',
    miscellaneous_are_not_available_click_add_miscellaneous_to_create_new_miscellaneous:
        '&&&&&& &&& &&&& &&&&&&& &&&& &&&& &&&&&&& &&&&&&',
    select_menu_items: '&& && &&',
    global_category: '&&&&& &&&&&&',
    global_categories_are_not_available: '&&&&& &&&&& &&&&&& &&&&& &&&&& &&&&&&',
    selected_category_is_mapped_to_your_business: '&&&&&& &&&&&& &&&&& &&&&& &&&&& &&&&&& &&&&&&',
    successfully_mapped: '&&&&&& &&&&&',
    global_products: '&&&&&& &&&&&',
    global_products_are_not_available: '&&&&& &&&&& &&&&& &&&&& &&&&',
    items_successfully_mapped: '&&&& &&&&&& &&&&&&',
    color_code: '&&&&& &&&&',
    color_code_is_required: '&&&& &&&& && &&&&&&',
    item_image: '&&&&& &&&&',
    sub_category: '&&&&& &&&&',
    sub_categories: '&&&&& &&&&',
    original_price: '&&& &&&',
    file_size: '&& &&',
    image_required: '&&&&&& &&&&&&&&&',
    to_creat_new_category_click_here: '&&&&& &&&&& &&&&& &&&&& &&&&',
    to_create_new_sub_category_click_three_dots_from_catogry_list:
        ' &&&&& &&&&& &&&&& &&&&& &&&& &&&&& &&&&& &&&&& &&&&& &&&& &&&&& &&&&& &&&&& &&&&& &&&&',
    sub_categories: '&&&& &&&&&&',
    count: '&&&&&&',
    menu_items: '&& &&',
    is_required: '&& &&',
    bills: '&&&',
    sales_summary_datas_are_not_available: '&&&& &&&& &&&& &&&& &&&',
    table_configuration: '&&&& &&&&&&&',
    choose: '&&&&&',
    highest_selling: '&&&& &&&&&',
    closing: '&&&',
    opening: '&&&',
    customer_on_board_summary_are_not_available: '&&& &&&& &&&& &&&& &&&& &&&',
    highest_selling_are_not_available: '&&& &&&& &&&& &&&& &&&& &&&',
    tip: 'Tip',
    miscellaneous_amount: '&& &&',
    tip_amount: '&& &&',
    enable_card_configuartion: '&&& &&&& &&&& &&&& &&&& &&&',
    enable_card_configuartion_pos: '&&& &&&& &&&& &&&& &&&& &&&',
    preparing: '&&',
    ready: '&&',
    queue: '&&',
    kot_order_details: '&&& &&&& &&&&',
    sales: '&&&&',
    kot_order: '&&&& &&&',
    in_progress: '&&&& &&&',
    take_away: '&&&&& &&&&',
    queue: '&&&',
    preparing: '&&&&&&',
    ready: '&&&&&',
    yes: '&&&',
    no: '&&',
    print_sales_summary: '&&& &&&& &&&&&&',
    please_select_customer: '&&&&& &&&&& &&&',
    set_pin: '&&& &&&',
    password_or_pin: '&&& &&&& &&&&',
    enable_edit_orders: '&&& &&&& &&&&',
    enable_edit_orders_pos: '&&& &&&& &&&&',
    edit_order: '&&& &&&&',
    csv: '&&&',
    card_configuration: '&&& &&&& &&&&',
    add_card_config: '&&& &&&& &&&&',
    Serial_no_is_required: '&&&& &&&& &&&&&',
    Ip_name_is_required: '&&&& &&&& &&&&',
    Serial_no: '&&&& &&&&',
    Ip_name: '&&&& &&&&',
    Machine_name_is_required: '&& &&&& &&&&&',
    Machine_name: '&&& &&&&&',
    edit_card_config: '&&& &&&& &&&&',
    subscription: '&&&&&&&&',
    subscription_view: '&&& &&&& &&&&',
    subscription_no: '&&& &&&& &&&&',
    due_date: '&&& &&&& &&&&',
    start_date: '&&& &&&& &&&&',
    status: '&&& &&&& &&&&',
    features: '&&& &&&& &&&&',
    new_subscription: '&&& &&&& &&&&',
    active_subscription: '&&& &&&& &&&&',
    one_year_validty: '&&& &&&& &&&&',
    yearly_subscription: '&&& &&&& &&&&',
    subscription_period: '&&& &&&& &&&&',
    one_year: '&&& &&&& &&&&',
    subscription_history: '&&& &&&& &&&&',
    subscription_number: '&&& &&&& &&&&',
    expire_with_valid_pack: '&&&& &&&&& &&&&&',
    active: '&&&&&',
    subscription_data_are_not_available: '&&&& &&&& &&&& &&&&&',
    card_configuration_are_not_available_click_add_card_config_to_add: '&&&& &&& &&&&&&&& &&&&',
    get_start: '&&&& &&&&&',
    profile_data: '&&&& &&&&&',
    update_your_business_image_now: '&&&& &&&&& &&&& &&&&&',
    update_some_common_settings_as_per_your_business_needs: '&&&& &&&&& &&&& &&&&&',
    outlet_timing: '&&&& &&&&&',
    profile_completion_status: '&&&& &&&&&',
    sales_unit: '&&&& &&&&&',
    employeement_type: '&&&& &&&&&',
    role_permission: '&&&& &&&&&',
    print: '&&&&&',
    customers_are_not_available: '&&&&&&&&&& &&&&&',
    config_settings: '&& &&',
    get_start_data: '&& && && &&&.',
    profile_details_data: '&& && && &&&.',
    business_image_data: '&& && && &&&.',
    config_data: '&& && && &&&.',
    users_data: '&& && && &&&.',
    outlet_timing_data: '&& && && &&&.',
    common_settings: '&& && && &&&.',
    loyalty_data: '&& && && &&&.',
    pay: '&&',
    enable_split_bill: '&& && && &&&',
    split_bill: '&& &&',
    enable_online: '&& &&',
    enable_online_order: '&& && &&',
    select_payment_type: '&& && &&',
    custom_domain: '&& &&',
    header_footer: '&& &&',
    homepage: '&&',
    about_us: '&& &&',
    order_now: '&& &&',
    contact: '&&',
    online_order_website_setup: '&& && &&',
    edit_online_order_website_setup: '&& && &&',
    mobile_should_be_more_than_10_numbers: '&& && && &&'
};
