/** @format */
export const APILINK = {
    product_update: `api/v1/product/update/`,
    coupen_get: `api/v1/coupon/`,
    product_get: 'api/v1/product/',
    product_insert: '/api/v1/product/insert',
    product_delete: `api/v1/product/delete/`,
    coupen_insert: 'api/v1/coupon/insert',
    auth_accept: 'api/v1/auth/is_accepted',
    order_get: 'api/v1/order/',
    rolemenu_get: 'api/v1/auth/rolemenus/',
    outletTimings_get: 'api/v1/outlet/insertOutletTime',
    outletTimings_insert: 'api/v1/outlet/insertOutletTime',
    refresh_token_update: `api/v1/auth/refresh_token`,
    download_excel_get: 'api/v1/upload/excel/productDownload',
    barcode_get: `api/v1/product/validate/barcode_validate`,
    excel_upload: `api/v1/upload/excel/productUpload`,
    category_excel_upload: `api/v1/upload/excel/categoryupload`,
    barcode_validate: 'api/v1/product/validate/barcode_validate',
    card_get: 'api/v1/cards/',
    feature_get: `api/v1/featureOption`,
    card_insert: 'api/v1/cards/insert',
    card_update: 'api/v1/cards/update/',
    instruction_get: '/api/v1/auth/setup_guide',
    subscription_insert: '/api/v1/subscription/insert',
    subscription_get: '/api/v1/subscription/',
    product_inline_update: '/api/v1/product/update_prod_price/',
    is_domain_exists: '/api/v1/auth/is_domain_exists',
    stripe_insert: '/api/v1/stripeConfig/insert',
    stripe_get: 'api/v1/stripeConfig/',
    stripe_update: '/api/v1/stripeConfig/update/1',
    website_setup_order_now: '/api/v1/websiteSetup/order_now',
    website_setup_about_us: '/api/v1/websiteSetup/about_us',
    website_setup_header_footer: '/api/v1/websiteSetup/header_footer',
    website_setup_home_page: '/api/v1/websiteSetup/home_page',
    website_setup_theme_setup: '/api/v1/websiteSetup/theme_setup',
    website_validation: '/api/v1/websitesetup/validate_website_setup',
    image_upload_insert: '/api/v1/image_upload/insert',
    image_upload: '/api/v1/image_upload',
    export_invoice_pdf: 'api/v1/upload/pdf/download_invoice_pdf',
    export_invoice_excel: 'api/v1/upload/excel/download_invoice_excel',
    website_setup_table_reservation: '/api/v1/websiteSetup/tbl_reservation',
    website_setup_weekend: '/api/v1/websiteSetup/weekend_special',
    audio_upload_get: '/api/v1/audio_upload'
};
